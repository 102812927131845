const messages = {
    version: 'Versão',
    id: '2.0.1',
    support: 'Suporte',
    "INVALID_SHARE": "LINK VENCIDO",
    HTTP_401_UNAUTHORIZED: 'Combinação de usuário e senha inválida.',
    ACCOUNT_IS_SUSPENDED: 'Sua conta está suspensa por falta de pagamento. Por favor, entre em contato com o suporte ou regularize seus débitos.',
    ACCOUNT_IS_SUSPENDED_TRUST: 'Sua conta está suspensa por falta de pagamento, mas não se preocupe, você pode realizar o desbloqueio de confiança antes de regularizar seus débitos.',
    ACCOUNT_IS_DISABLED: 'Esta conta foi desabilitada, contate o administrador.',
    action_cancel: 'Ação Cancelada',
    ACCOUNT_IS_BLOCKED: 'Esta conta foi desabilitada, contate o administrador.',
    errors: {
        ACCOUNT_IS_BLOCKED: 'Esta conta foi desabilitada, contate o administrador.',
        USER_DEVICE_LIMIT_REACHED: "Limite de dispositivos do usuário atingido",
        "ACCOUNT_IS_READONLY": "A conta está em modo somente leitura",
        "ACCOUNT_IS_DEVICE_READONLY": "A conta está em modo somente leitura para dispositivos",
        "WRITE_ACCESS_DENIE": "Erro, não é possível gravar o dispositivo",
        action_cancel: 'Ação Cancelada'
    },
    NEW_VERSION_AVAILABLE: 'NOVA VERSÃO DISPONÍVEL 2.0.1',
    ALL_TASK_READY: "Todas as tarefas concluídas com sucesso.",
    "FILL_ALL_FIELDS": "Por favor, preencha todos os campos.",
    "INVALID_LOGIN_DATA": "Desculpe, parece que você inseriu dados incorretos.",
    "NO_DATA_TEXT": "Não há dados disponíveis",
    "NO_MATCH_TEXT": "Não Encontrado",
    output_type: 'Tipo de Saída',
    output_type_text: 'Texto',
    output_type_Numeric: 'Numérico',
    output_type_Logical: 'Lógico',
    expression: 'Expressão',
    Please_input: 'Por favor, insira',
    Atribut: 'Atributo',
    descriptionCommand: 'Descrição',
    Command_2: 'Comando',
    do_not_test: 'Não teste',
    Test_Attribute_on: 'Testar atributo ativado',
    Outcome: 'Resultado',
    active_text: 'Aplicar este atributo aos meus favoritos',
    devices: 'Dispositivos',
    Attribute_Registry: 'Atributo computado registrado com sucesso.',
    Binding_attribute_device: 'Atributo vinculado ao dispositivo',
    registry_attribute: 'Registrando seu atributo...',
    computed_attribute_deleted: 'Atributo computado excluído como evento.',
    computed_attribute_deleted_confirmation: 'Você realmente deseja excluir este atributo computado? Tem certeza?',
    "LOADING": "Carregando...",
    "route": {
        "start": "Início da Rota",
        "end": "Fim da Rota",
        "empty": "Nenhuma rota para o período selecionado."
    },
    "sorting": {
        "name-asc": "Nome Ascendente",
        "name-desc": "Nome Descendente",
        "lastUpdate-asc": "Última Atualização Ascendente",
        "lastUpdate-desc": "Última Atualização Descendente",
        "state-motion": "Movimento",
        "state-anchor": "Âncora",
        "state-locked": "Travado",
        "state-ignition": "Ignição",
        "state-driver": "Motorista",
        "state-alert": "Alerta"
    },
    "customize": "Personalizar",
    "preferences": "Preferências",
    "search": "Pesquisar",
    "page": "Página",
    "now": "Agora",
    "days": "dias",
    "hours": "horas",
    "minutes": "minutos",
    "seconds": "segundos",
    "minute": "minuto",
    "lessMinute": "<1 minuto",
    "yes": "Sim",
    "no": "Não",
    "new": "Novo",
    "all": "Todos",
    "online": "Online",
    "offline": "Offline",
    "unknown": "Desconhecido",
    "rememberme": "Lembrar-me",
    "enabled": "Habilitado",
    "disabled": "Desabilitado",
    "fixed": "Fixado",
    "percent": "Porcentagem",
    "always": "Sempre",
    "automatic": "Automático",
    "set": "Definido",
    "unlimited": "Ilimitado",
    "ok": "OK",
    "save": "Salvar",
    "cancel": "Cancelar",
    "delete": "Deletar",
    "danger": "Perigo!",
    "info": "Aguarde...",
    "success": "Sucesso!",
    "sure": "Tem certeza?",
    "ops": "Ops",
    "userCancel": "Operação cancelada pelo usuário.",
    "today": "Hoje",
    "yesterday": "Ontem",
    "thisweek": "Últimos 7 dias",
    "thismonth": "Últimos 30 dias",
    "threemonths": "Últimos 90 dias",
    "startDate": "Data de Início",
    "endDate": "Data de Fim",
    "to": "até",
    "weekDays": {
        "SU": "Dom",
        "MO": "Seg",
        "TU": "Ter",
        "WE": "Qua",
        "TH": "Qui",
        "FR": "Sex",
        "ST": "Sáb"
    },
    units: {
        currency: (ctx) => {
            return "R$ " + parseFloat(ctx.named('value')).toFixed(2);
        },
        speedUnit: (ctx) => {
            return Math.round(ctx.named('speed') * 1.852) + ' km/h';
        },
        speedUnitReverse: (ctx) => {
            return Math.round(ctx.named('speed') / 1.852) + ' km/h';
        },
        distanceUnit: (ctx) => {
            return ((ctx.named('distance') / 1000) * 0.621371).toFixed(2) + ' mi';
        },
        miles: (ctx) => {
            return ((ctx.named('distance') / 1000) * 0.621371).toFixed(2) + ' mi';
        },
        km: (ctx) => {
            return (ctx.named('distance') / 1000).toFixed(2) + ' km';
        },
        mph: (ctx) => {
            return Math.round(ctx.named('speed') * 1.15078) + ' mph';
        },
        mphReverse: (ctx) => {
            return Math.round(ctx.named('speed') / 1.15078) + ' mph';
        },
        kmh: (ctx) => {
            return Math.round(ctx.named('speed') * 1.852) + ' km/h';
        },
        kmhReverse: (ctx) => {
            return Math.round(ctx.named('speed') / 1.852) + ' km/h';
        },
        kn: (ctx) => {
            return ctx.named('speed') + ' kn';
        },
        knReverse: (ctx) => {
            return ctx.named('speed') + ' kn';
        },
        ignition: (ctx) => {
            return (ctx.named('value')) ? 'Sim' : 'Não';
        },
        blocked: (ctx) => {
            return (ctx.named('value')) ? 'Sim' : 'Não';
        },
        motion: (ctx) => {
            return (ctx.named('value')) ? 'Sim' : 'Não';
        },
        valid: (ctx) => {
            return (ctx.named('value')) ? 'Sim' : 'Não';
        },
        distance: (ctx) => {
            return (ctx.named('value') / 1000).toFixed(2) + ' km';
        },
        totalDistance: (ctx) => {
            return (ctx.named('value') / 1000).toFixed(2) + ' km';
        },
        speed: (ctx) => {
            return Math.round(ctx.named('value') * 1.852) + ' km/h';
        },
        serverTime: (ctx) => {
            return new Date(ctx.named('value')).toLocaleString();
        },
        deviceTime: (ctx) => {
            return new Date(ctx.named('value')).toLocaleString();
        },
        fixTime: (ctx) => {
            return new Date(ctx.named('value')).toLocaleString();
        }
    },
    menu: {
        autoLink: 'Adicionar Dispositivo',
        devices: 'Dispositivos',
        history: 'Histórico',
        geofence: 'Geocerca',
        commands: 'Comandos',
        groups: 'Grupos',
        notifications: 'Notificações',
        events: 'Eventos',
        reports: 'Relatórios',
        reportsHistory: 'Rotas',
        reportsResume: 'Resumo',
        reportsEvents: 'Eventos',
        reportsStops: 'Paradas',
        reportsTravels: 'Viagens',
        computedAttributes: 'Atributos Computados',
        soundNotification: 'Ouvir Notificações',
        noSoundNotification: 'Nao Ouvir Notificações'
    },
    usermenu: {
        account: 'Minha Conta',
        users: 'Usuários',
        theme: 'Aparência',
        logs: 'Logs Avançados',
        integrations: 'Integrações',
        invoices: 'Minhas Faturas',
        billing: 'Gestão de Cobranças',
        reseller: 'Revenda',
        computedAttributes: 'Atributos Computados',
        server: 'Servidor',
        notifications: 'Notificações',
        notifications2: 'Eventos',
        drivers: 'Motoristas',
        calendars: 'Calendários',
        maintenance: 'Manutenção',
        logout: 'Sair'
    },
    "theme": {
        androidApp: "Aplicativo da Android",
        appleApp: "Aplicativo da Apple",
        dataPolicy: "Política de Dados",
        "edit": "Editar Tema",
        "general": "Geral",
        "login": "Tela de Login",
        "logos": "Logos",
        "icon": "Ícone",
        "generalColors": "Cores Gerais",
        "textColors": "Cores de Texto",
        "otherColors": "Outras Cores",
        "appName": "Nome do Serviço",
        "whatsapp": "WhatsApp",
        "internalLogo": "Logotipo Interno",
        "logoAsImage": "Mostrar como imagem",
        "logoAsText": "Mostrar como texto",
        "filterColor": "Cor do Filtro",
        "backgroundImage": "Imagem de Fundo",
        "logoOnLogin": "Na Tela de Login",
        "logoOnHead": "Logotipo Interno",
        "logoSize": "Tamanho do Logotipo",
        "backgroundColor": "Cor de Fundo",
        "generalWhite": "Luz Geral",
        "primary": "Primário",
        "textColor": "Cor do Texto",
        "generalBlack": "Escuro Geral",
        "variant1": "Variante Primária 1",
        "variant2": "Variante Primária 2",
        "variant3": "Variante Primária 3",
        "variant4": "Variante Primária 4",
        "variant5": "Variante Primária 5",
        "variant6": "Variante Primária 6",
        "variant7": "Variante Primária 7",
        "variant8": "Variante Primária 8",
        "variant9": "Variante Primária 9",
        "textPrimary": "Texto Primário",
        "textRegular": "Texto Regular",
        "textSecondary": "Texto Secundário",
        "textWhite": "Luz de Texto",
        "success": "Sucesso",
        "successVariant1": "Variante de Sucesso 1",
        "successVariant2": "Variante de Sucesso 2",
        "alert": "Alerta",
        "alertVariant1": "Variante de Alerta 1",
        "alertVariant2": "Variante de Alerta 2",
        "danger": "Perigo",
        "dangerVariant1": "Variante de Perigo 1",
        "dangerVariant2": "Variante de Perigo 2",
        "info": "Informação",
        "infoVariant1": "Variante de Informação 1",
        "infoVariant2": "Variante de Informação 2"
    },
    report: {
        selectType: 'Selecione o tipo de relatório desejado',
        history: 'Rotas',
        resume: 'Resumo',
        stops: 'Paradas',
        travels: 'Viagens',
        events: 'Eventos',
        load: 'Carregar',
        export: 'Exportar',
        showMarkers: 'Mostrar Marcadores'
    },
    alarms: {
        none: 'Nenhum Alerta',
        alarm: 'Alarme',
        alarms: 'Alarmes',
        general: 'Geral',
        sos: 'SOS',
        vibration: 'Vibração',
        lowspeed: 'Velocidade Baixa',
        overspeed: 'Velocidade Alta',
        movement: 'Movimento',
        fallDown: 'Queda',
        lowPower: 'Baixa Tensão da Bateria',
        lowBattery: 'Bateria Fraca',
        fault: 'Falha',
        powerOff: 'Ignição Desligada',
        powerOn: 'Ignição Ligada',
        door: 'Porta',
        unlock: 'Destravado',
        lock: 'Travado',
        geofence: 'Geocerca',
        geofenceEnter: 'Entrada na Geocerca',
        geofenceExit: 'Saída da Geocerca',
        gpsAntennaCut: 'Corte de GPS',
        accident: 'Acidente',
        tow: 'Reboque',
        idle: 'Ocioso',
        highRpm: 'Alta Rotação',
        hardAcceleration: 'Aceleração Brusca',
        hardBraking: 'Frenagem Brusca',
        hardCornering: 'Curva Brusca',
        laneChange: 'Troca de Faixa',
        fatigueDriving: 'Fadiga',
        powerCut: 'Energia Cortada',
        powerRestored: 'Energia Restaurada',
        jamming: 'Jamming',
        temperature: 'Temperatura',
        parking: 'Estacionamento',
        shock: 'Choque',
        bonnet: 'Capô',
        footBrake: 'Freio de Mão',
        fuelLeak: 'Vazamento de Combustível',
        tampering: 'Adulteração',
        removing: 'Removendo'
    },
    graph: {
        graphicType: 'Tipo de Gráfico'
    },
    integrations: {
        title: 'Integrações',
        enable: 'Habilitar',
        configure: 'Configurar',
        asaas: {
            title: 'Asaas',
            inputs: {
                email: 'Email',
                token: 'Token',
                sandbox: 'Habilitar Ambiente de Teste'
            }
        },
        email: {
            title: 'E-Mail',
            inputs: {
                username: 'Nome de Usuário',
                password: 'Senha',
                mailfrom: 'E-mail Remetente',
                mailfrom_name: 'Nome do Remetente',
                smtp_host: 'Host SMTP',
                smtp_port: 'Porta SMTP'
            }
        },
        invoice: {
            title: 'Fatura Tarkan',
            inputs: {
                invoices_title: 'Integração de Fatura',
                password: 'Senha',
                mailfrom: 'E-mail Remetente',
                mailfrom_name: 'Nome do Remetente',
                smtp_host: 'Host SMTP',
                smtp_port: 'Porta SMTP'
            }
        }


    },
    notification: {
        events: 'Eventos',
        evento: 'Eventos',
        newEvent: 'Novo Evento',
        command: 'Comandos',
        listen: 'Teste Som de Notificação',
        autoAdd: 'Adicionar automaticamente a novos usuários',
        fillRequiredFields: 'Os campos Tipo, Canais, Alarmes devem ser preenchidos',
        fillRequiredFields2: 'Os campos Tipo, Canais, Dispositivos, Calendário devem ser preenchidos',
        test: 'Notificação de teste',
        testSent: 'A notificação de teste foi enviada com sucesso',
        search: 'Pesquisar Notificação',
        deleted: 'Notificação excluída com sucesso.',
        selectError: 'Você precisa selecionar um usuário para editar.',
        saveError: 'Não foi possível salvar sua notificação.',
        confirmDelete: 'Você está deletando uma notificação de "%type%", deseja continuar?',
        saving: 'Salvando sua notificação',
        saved: 'Sua notificação foi salva com sucesso.',
        linkToDevice: 'Atribuindo ao dispositivo "%name%"',
        linkToGroup: 'Atribuindo ao grupo "%name%"',
        linkToUser: 'Atribuindo ao usuário "%name%"',
        title: 'Gerenciar Notificações',
        title2: 'Gerenciar Eventos',
        calendar: 'Calendário',
        notification: 'Notificação',
        notification2: 'Evento',
        editEvent: 'Editar Eventos',
        selectCommand: 'Seleção de Comandos',
        selectCalenders: 'Seleção de Calendário',
        notifications: 'Notificações',
        newNotification: 'Cadastrar Notificação',
        editNotification: 'Editar Notificação',
        deviceNotAssigned: 'Precisa de selecionar um dispositivo para Salvar',
        add: 'Adicionar',
        remove: 'Excluir',
        edit: 'Editar',
        type: 'Tipo',
        all: 'Todos os Dispositivos',
        alarms: 'Alarmes',
        channel: 'Canais',
        customize: 'Personalizar',
        position: 'Posição',
        color: 'Cor',
        sound: 'Som de Notificação',
        soundURL: 'Endereço URL do SOM (mp3,wav,ogg)',
        pin: 'Fixar',
        sounds: {
            mute: 'Mudo',
            custom: 'URL',
            audio1: '1 - Apple',
            audio2: '2 - Huawei',
            audio3: '3 - Huawei Tone',
            audio4: '4 - Apple Tone',
            audio5: '5 - Huawei Cave',
            audio6: '6 - Apple Pay',
            audio7: '7 - Brilhante',
            audio8: '8 - Faixa de Pedestres',
            audio9: '9 - Faixa de Pedestres Japão',
            audio10: '10 - Definir',
            audio11: '11 - iPhone Ding',
            audio12: '12 - Netflix',
            audio13: '13 - Moeda',
            audio14: '14 - Interruptor',
            audio15: '15 - Anéis Sônicos',
            audio16: '16 - Alarme de Polícia',
            audio17: '17 - Tom de Polícia',
            audio18: '18 - Alerta de Polícia',
            audio19: '19 - Notificação de Polícia',
            audio20: '20 - Polícia',
            audio21: '21 - Claro',
            audio22: '22 - Apito',
            audio23: '23 - S8/NOTE',
            audio24: '24 - Alarme'
        },
        colors: {
            'soft-red': 'Vermelho Claro',
            'red': 'Vermelho',
            'soft-green': 'Verde Claro',
            'green': 'Verde',
            'soft-yellow': 'Amarelo Claro',
            'yellow': 'Amarelo',
            'soft-info': 'Azul Claro',
            'info': 'Azul'
        },
        positions: {
            'top-right': 'Superior Direito',
            'top-left': 'Superior Esquerdo',
            'bottom-right': 'Inferior Direito',
            'bottom-left': 'Inferior Esquerdo'
        },
        types: {
            queuedCommandSent: 'Comando de Fila Enviado',
            alarm: 'Alarme',
            deviceOnline: 'Dispositivo Online',
            deviceOffline: 'Dispositivo Offline',
            textMessage: 'Mensagem de Texto',
            deviceUnknown: 'Dispositivo Desconhecido',
            geofenceExit: 'Saída da Geocerca',
            geofenceEnter: 'Entrada na Geocerca',
            ignitionOff: 'Ignição Desligada',
            ignitionOn: 'Ignição Ligada',
            deviceMoving: 'Dispositivo Movendo',
            deviceStopped: 'Dispositivo Parado',
            deviceOverspeed: 'Dispositivo Acima da Velocidade',
            commandResult: 'Resultado do Comando',
            driverChanged: 'Trocou de Motorista',
            deviceFuelDrop: 'Queda de Combustível',
            maintenance: 'Manutenção',
            deviceInactive: 'Dispositivo Inativo',
        },
        channels: {
            web: 'Web',
            firebase: 'Celular',
            telegram: 'Telegram',
            sms: 'SMS',
            command: 'Comandos',
            mail: 'E-Mail'
        },
        messages: {
            test: 'Notificação de Teste',
            alarm: 'Alarme',
            deviceOnline: 'Dispositivo Online',
            deviceOffline: 'Dispositivo Offline',
            textMessage: 'Mensagem de Texto',
            deviceUnknown: 'Dispositivo Desconhecido',
            geofenceExitName: 'Saída da Geocerca: %name%',
            geofenceExit: 'Saiu de uma Geocerca.',
            geofenceEnterName: 'Entrada na Geocerca: %name%',
            geofenceEnter: 'Entrou em uma Geocerca.',
            ignitionOff: 'Ignição Desligada',
            ignitionOn: 'Ignição Ligada',
            deviceMoving: 'Dispositivo Movendo',
            deviceStopped: 'Dispositivo Parado',
            deviceOverspeed: 'Dispositivo Acima da Velocidade',
            commandResult: 'Resultado do Comando: %result%',
            driverChanged: 'Trocou de Motorista',
            deviceFuelDrop: 'Queda de Combustível',
            maintenance: 'Manutenção',
            deviceInactive: 'Dispositivo Inativo'
        }
    },
    login: {
        username: 'Nome de Usuário',
        password: 'Senha',
        your_email: 'Seu E-mail',
        your_password: 'Sua Senha',
        signin: 'Acessar',
        suspended: 'Conta Suspensa',
        lost_password: 'Recuperar Senha',
        lost_send: 'Solicitação de senha enviada. Em instantes, você receberá um e-mail de confirmação.',
        password_changed: 'Sua senha foi modificada com sucesso. Agora você pode realizar o login com sua nova senha.',
        register: 'Cadastrar',
        charge: 'Acessando compartilhamento, por favor aguarde...',
        welcome: 'Bem-vindo',
        android_app: 'App Android',
        apple_app: 'App iOS',
        data_policy: 'Política de Dados',
        invalid_username: 'Nome de usuário inválido'
    },
    actions: {
        custom: 'Comando Personalizado',
        engineStop: 'Bloquear Motor',
        engineResume: 'Desbloquear Motor',
        anchorEnable: 'Habilitar Âncora',
        anchorDisable: 'Desabilitar Âncora',
        deviceIdentification: 'Identificação do Dispositivo',
        positionSingle: 'Posição Única',
        alarmArm: 'Ativar Alarme',
        alarmDisarm: 'Desativar Alarme',
        outputControl: 'Controle de Saída',
        rebootDevice: 'Reinicializar Dispositivo',
        offline: 'Desligado'
    },

    user: {
        all: "Total de Usuários",
        admins: "Administradores",
        suspended: "Suspenso",
        debtors: "Devedores",
        reportTitle: "Relatório de Usuários",
        reportCategory: "Categoria: %category%",
        reportTotalUsers: "Total de Usuários Analisados: %count%",
        reportTotalDebt: "Valor Total da Dívida: %value%",
        generatePDF: 'Gerar PDF',
        generateExcel: 'Gerar EXCEL',
        'report': 'Relatorio Usuario',
        'ID': 'ID',
        'Nombre': 'Nome',
        'Correo': 'Email',
        'Administrador': 'Administrador',
        'Bloqueado': 'Bloqueado',
        'Telefono': 'Telefone',
        'CPF-CNPJ': 'CPF/CNPJ',
        changesesion: 'Faça login como outro usuário',
        billing: "Faturamento",
        billingEnable: 'Habilitar Faturamento',
        billingCpfCnpj: "CPF/CNPJ",
        billingTrustUnlock: 'Permitir Desbloqueio de Confiança',
        billingTrustUnlockDays: 'Quantos dias de confiança?',
        billingSuspension: "Habilitar Suspensão Automática",
        billingSuspensionDays: "Aguardar quantos dias após o vencimento antes da suspensão",
        billingDescription: "Descrição da Cobrança",
        billingDate: "Dia do Primeiro Vencimento",
        billingPrice: "Valor da Fatura",
        billingFine: "Valor da Multa",
        billingFineMode: "Modo da Multa",
        billingInterest: "Juros",
        billingDisccount: "Desconto",
        billingDisccountMode: "Modo de Desconto",
        billingDisccountDays: "Quantos dias antes do vencimento para o desconto",
        accountInfo: "Detalhes da Conta",
        preferences: "Preferências do Usuário",
        accountAddress: "Endereço do Usuário",
        address: 'Endereço',
        permissions: "Permissões do Usuário",
        new: 'Novo Usuário',
        device: 'Dispositivo',
        expirationTime: 'Data de Expiração',
        shareLink: 'Link de Compartilhamento',
        logs: 'Logs',
        users: 'Usuários',
        user: 'Usuário',
        search: 'Pesquisar Usuário',
        add: 'Cadastrar Usuário',
        edit: 'Editar Usuário',
        remove: 'Deletar Usuário',
        title: 'Gerenciar Usuários',
        id: 'ID',
        name: 'Nome',
        phone: 'Telefone',
        email: "E-Mail",
        password: "Senha",
        passwordConfirm: "Confirmar Senha",
        admin: "Administrador",
        disabled: "Bloqueado",
        language: "Idioma",
        map: "Mapa",
        latitude: "Latitude",
        longitude: "Longitude",
        twelveHourFormat: "Relógio de 12 Horas",
        zoom: "Zoom",
        coordinateFormat: "Formato de Coordenadas",
        userStatus: "Estado do Usuário",
        readOnly: "Somente Leitura",
        editDevices: "Editar Dispositivos",
        limitCommands: "Limitar Comandos",
        expirationDate: "Data de Validade",
        deviceLimit: "Limite de Dispositivos",
        userLimit: "Limite de Usuários",
        token: "Token de Acesso",
        selectUser: "Você precisa selecionar um usuário",
        cannotDeleteSelf: "Você não pode se deletar!",
        cannotDeleteAdmin: "Você não pode deletar um admin superior a você!",
        confirmDelete: "Você está excluindo o usuário {name}, deseja continuar?",
        dangerTitle: "Perigo!",
        delete: "Excluir",
        cancel: "Cancelar",
        success: "Usuário deletado com sucesso.",
        successMessage: "Usuário deletado com sucesso.",
        successTitle: "Delete Do Usuário.",


        error: {
            cannotDeleteSelf: "Você não pode se deletar!",
            error: "Erro",
            save: 'Erro!',
            REGISTRATION_DISABLED: "A criação de novos usuários está temporariamente bloqueada. Por favor, entre em contato com o administrador.",
            selectUser: 'Selecione um usuário para editar.',
            checkForm: 'Verifique os dados do formulário.',
            superior: 'Você não pode editar um administrador superior a você.',
            select: 'Selecione um usuário para editar.',
            USER_NOT_AUTHED: 'Atualize a sua página',
            WRITE_ACCESS_DENIED: 'ACESSO NEGADO'
        },
        form: {
            userNameEmpty: 'Digite um nome e sobrenome',
            userNameLength: 'Mínimo de 3 caracteres',
            emailEmpty: 'Digite um nome de usuário válido',
            emailLength: 'Mínimo de 4 caracteres exigido',
            passwordEmpty: 'Digite uma senha',
            passwordLength: 'Mínimo de 4 caracteres exigido',
        },
        advanced: {
            reseller: 'Revendedor',
            subReseller: 'Pode criar novas revendas',
            domainReseller: 'Domínio da Revenda',
            limitReseller: 'Limite de Revendas',
            lock: 'Bloquear',
            unlock: 'Desbloquear',
            command: 'Enviar Comandos',
            create: 'Criar',
            edit: 'Editar',
            delete: 'Deletar',
            self: 'Pode se editar',
            devices: 'Dispositivos',
            reports: 'Relatórios',
            geofences: 'Geocercas',
            commands: 'Comandos',
            groups: 'Grupos',
            notifications: 'Notificações',
            events: 'Eventos',
            drivers: 'Motoristas',
            calendar: 'Calendários',
            anchor: 'Âncora',
            share: 'Compartilhamento',
            users: 'Usuários',
            attributes: 'Atributos Computados',
            calendars: 'Calendários',
            maintenance: 'Manutenções',
        },

        // Nuevas claves agregadas
        cep: "Digite o CEP",
        rua: "Rua",
        hausenumber: "Número Casa",
        complemento: "Complemento",
        bairro: "Bairro",
        cidade: "Cidade",
        estado: "Estado",

        // Nuevos campos de contacto
        addrerss: 'Endereço',
        communication: 'Comunicação',
        phonePersonal: 'Telefone Particular',
        whatsappPersonal: 'WhatsApp Pessoal',
        emailPersonal: 'E-mail Particular',
        phoneCommercial: 'Telefone Comercial',
        whatsappCommercial: 'WhatsApp Comercial',
        emailCommercial: 'E-mail Comercial',
        placeholderCep: 'Digite o CEP',
        placeholderPhonePersonal: 'Digite o número de telefone particular',
        placeholderWhatsappPersonal: 'Digite o número do WhatsApp pessoal',
        placeholderEmailPersonal: 'Digite o e-mail particular',
        placeholderPhoneCommercial: 'Digite o número de telefone comercial',
        placeholderWhatsappCommercial: 'Digite o número do WhatsApp comercial',
        placeholderEmailCommercial: 'Digite o e-mail comercial'
    },
    map: {
        showCluster: "Mostrar Cluster",
        "showGroups": "Mostrar Grupos",
        "showGeofences": "Mostrar Geocercas",
        "showNames": "Mostrar Nomes",
        "showPlates": "Mostrar Placas",
        "showPrecision": "Mostrar Precisão",
        "showStatus": "Mostrar Status",
        "copytransfer": "copiado para area de transferecia",
        devices: {
            "default": "Padrão",
            "arrow": "Seta",
            "person": "Pessoa",
            "animal": "Animal",
            "bicycle": "Bicicleta",
            "motorcycle": "Motocicleta",
            "scooter": "Scooter",
            "car": "Carro",
            "pickup": "Pick-Up",
            "van": "Van",
            "truck": "Caminhão",
            "truck1": "Caminhão 1",
            "truck2": "Caminhão 2",
            "tractor": "Trator",
            "boat": "Barco",
            "ship": "Navio",
            "bus": "Ônibus",
            "train": "Trem",
            "trolleybus": "Trolebus",
            "tram": "Boné",
            "crane": "Guindaste",
            "plane": "Avião",
            "helicopter": "Helicóptero",
            "offroad": "Offroad"
        }
    },
    device: {
        filtered: 'Filtrados',
        generatePDF: 'Gerar PDF',
        generateExcel: 'Gerar EXCEL',
        speedLimit: 'Velocidade Limite',
        sensors: 'Sensores',
        otherAttributes: 'Outros Atributos',
        "id": "ID",
        "placa": "Placa",
        "comando": "Comando",
        "commandName": "Comando",
        "calendar": "Calendário",
        "calendarName": "Calendário",
        ValoMensual: 'Valor Mensal',
        dataSimVal: 'Data de validade do SIM',
        dataSimAct: 'Data de ativação do SIM',
        apn: 'APN',
        protocol: 'Protocolo',
        manageQrCode: 'QrCode Dispositivo',
        noDriver: 'Sem Motorista',
        anchorDisabled: 'Âncora Desativada',
        anchorEnabled: 'Âncora Habilitada',
        odometer: 'Odômetro Virtual (KM)',
        category: 'Categoria',
        motion: 'Movimento',
        stopped: 'Parado',
        lastStoppedTime: 'Parado há',
        loading: 'Carregando dados do dispositivo...',
        driver: 'Motorista',
        isQrDriverId: 'Motorista identificado por QrCode',
        showQrCode: 'QrCode do Motorista',
        shareLink: 'Compartilhar Link Temporário',
        shareMaps: 'Compartilhar no Google Maps',
        shareStreet: 'Compartilhar no Street View',
        openMaps: 'Google Maps',
        openStreet: 'Street View',
        saveError: 'Não foi possível salvar seu dispositivo.',
        confirmDelete: 'Você está deletando o dispositivo "%name%", deseja continuar?',
        saving: 'Salvando seu dispositivo',
        saved: 'Operação realizada com sucesso.',
        attributions: 'Atribuições',
        title: 'Gerenciar Dispositivos',
        devices: 'Dispositivos',
        device: 'Dispositivo',
        logs: 'Logs',
        share: 'Compartilhar',
        openExternal: 'Abrir no',
        routes: 'Rotas',
        graphic: 'Gráfico',
        startOdometer: 'Início do Odômetro',
        endOdometer: 'Fim do Odômetro',
        averageSpeed: 'Velocidade Média',
        maxSpeed: 'Velocidade Máxima',
        engineHours: 'Tempo Ligado',
        fuel: 'Combustível',
        spentFuel: 'Custo de Combustível',
        currency: 'Moeda',
        fuelPrice: 'Preço do Combustível',
        litersx100km: 'LTS. por cada 100 km',
        tank: 'LTS Tanque Combustível',
        duration: 'Duração',
        battery: 'Bateria',
        batteryLevel: 'Nível da Bateria',
        ignitionOn: 'Ligado',
        ignitionOff: 'Desligado',
        blocked: 'Bloqueado',
        unblocked: 'Desbloqueado',
        "rssi": 'Intensidade do Sinal',
        network: 'Rede',
        sat: 'Satélite',
        sattelites: 'Satélites',
        power: 'Tensão de Alimentação',
        alarm: 'Alarme',
        alarms: 'Alarmes',
        unknown: 'Desconhecido',
        disabled: 'Desativado',
        new: 'Novo',
        moving: 'Movendo',
        stoped: 'Parado',
        noPosition: 'Sem Posição',
        lastPosition: 'Localização Atual',
        updatedAt: 'Atualizado em',
        positionSpeed: 'Velocidade Atual',
        distance: 'Distância Total',
        online: 'Online',
        offline: 'Offline',
        lastPositionTime: 'Hora da Última Posição',
        lastUpdateTime: 'Tempo da Última Posição',
        chip: 'Chip',
        follow: 'Seguir',
        unfollow: 'Deixar de Seguir',
        trail: 'Mostrar Rastro',
        untrail: 'Ocultar Rastro',
        zoom: 'Aproximar',
        details: 'Detalhes do Veículo',
        lock: 'Bloquear',
        unlock: 'Desbloquear',
        send_command: 'Enviar Comando',
        confirm_lock: 'Deseja realmente bloquear o veículo ',
        questio_bloked: 'Deseja realmente bloquear o veículo ',
        confirm_unlock: 'Deseja realmente desbloquear o veículo ',
        question_noblocked: 'Enviar Comando desbloquear o veículo ',
        question_yesblocked: 'Enviar Comando bloquear o veículo ',
        confirm_command: 'Você está enviando um comando personalizado para o veículo %name%, alguns comandos podem prejudicar o funcionamento do sistema! Deseja continuar?',
        command_sent: 'Comando enviado com sucesso.',
        edit: 'Editar',
        add: 'Cadastrar',
        remove: 'Excluir',
        search: 'Pesquise por Nome, IMEI, Placa, Grupo, ICCID, Operadora, Telefone, Modelo ou qualquer outro atributo',
        updated: 'Atualizado',
        imei: 'Identificador do Dispositivo (IMEI)',
        name: 'Nome do Dispositivo',
        status: 'Status',
        iccid: 'ICCID',
        phone: 'Telefone',
        operator: 'Operadora',
        broker: 'Brocker',
        model: 'Modelo',
        plate: 'Placa',
        renavam: 'Renavam',
        color1: 'Cor Primária',
        color2: 'Cor Secundária',
        icon: 'Selecione o Veículo',
        hue: 'Colorir',
        saturate: 'Saturar',
        brightness: 'Brilho',
        temperature: 'Temperatura',
        historyEvents: 'Eventos Recentes',
        historyPosition: 'Histórico de Movimento',
        form: {
            nameEmpty: 'Por favor, insira um nome para o dispositivo',
            uniqueIdEmpty: 'Por favor, insira o identificador único do dispositivo',
        },
        streetview: 'StreetView',
        questionBloked: 'Deseja realmente bloquear o veículo ',
        success: "Sucesso",
        action_cancel: "Ação cancelada pelo usuário",
        question_blocked: "Deseja realmente desbloquear o veículo ",
        question_del1: "Deseja realmente deletar o veículo",
        question_del2: "Está de acordo?",
        Info: "Informação",
        device_del: "Dispositivo deletado com sucesso.",
        marca: "Marca",
        color: "Cor",
        motor: "Número do Motor",
        date: "Data de Fabricação",
        observations: "Observações",
        observation: "Observação",
        vin: "Número VIN",
        chassis: "Número do Chassi",
        zeroodometrocombustible: "Zerar Odômetro de Combustível",
        error_device_del: "Ocorreu um erro inesperado ao excluir seu dispositivo.",
        rpm: 'RPM',
        error: "Erro",
        ultconection: "Última Atualização Conexão",
        "iccidhold": "Insira o ICCID",
        "phonehold": "Insira o número do SIM",
        "brokerhold": "Insira o Brocker",
        "operatorhold": "Insira o Operadora",
        "apnhold": "Insira o APN",
        "ValoMensualhold": "Valor Mensal",
        "dataSimActhold": "Data de ativação",
        "dataSimValhold": "Data de validade"


    },
    instalations: {
        "install": "Instalação",
        "empresa": "Empresa de Instalação",
        "installer": "Instalador",
        "phone": "Telefone",
        "email": "Email",
        "observation": "Observação",
        "data": "Data de Instalação",
        "address": "Endereço"
    },
    log: {
        "Mess_Carga": "Carregando registros, por favor, aguarde...",
        "Log": "Log",
        "Device_acceso": "Dispositivo de Acesso",
        "Mess_log_ok": "Tentativa de login realizada com sucesso.",
        "Mess_log_out": "Tentativa de login falhou.",
        "Geocerca": "Geocerca",
        "edit": "Editado:",
        "Mess_user_edit": "Tentativa de deletar usuário falhou",
        "Link_Share_add": "Link de compartilhamento criado",
        "Link_Share_out": "Tentativa de compartilhamento falhou",
        "Link_Share_edit": "Link de compartilhamento editado",
        "Link_Share_edit_err": "Tentativa de editar compartilhamento falhou",
        "usser_motor_add": "Usuário para acesso de motorista criado.",
        "usser_motor_out": "Tentativa de criar acesso de motorista falhou.",
        "send_comand": "Comando enviado",
        "send_comand_err": "Envio de comando falhou.",
        "Blocked_motorista": "Bloqueio realizado pela regra de motorista.",
        "Unblocked_motorista": "Desbloqueio realizado pela regra de motorista.",
        "edit_device_err": "Tentativa de editar o veículo falhou.",
        "device": "Dispositivo",
        "excluido": "Excluído",
        "falla": "Falhou",
        "anchor_activ": "Âncora Ativada",
        "ancora_err": "Tentativa de ativar âncora falhou.",
        "geofence_add": "Geocerca criada",
        "geofence_out": "Tentativa de criar geocerca falhou.",
        "ancora_aut": "Âncora Desativada",
        "ancora_desact_err": "Tentativa de desativar âncora falhou.",
        "deleted": "Removido de",
        "geofence_out_err": "Tentativa de remover geocerca falhou.",
        "geofence_out_ok": "Geocerca Removida",
        "idem": "Sem alteração."
    },
    invoice: {
        id: 'ID',
        dueDate: 'Data de Vencimento',
        lastDueDate: 'Última Data de Vencimento',
        value: 'Valor',
        status: 'Status',
        paidDate: 'Data de Pagamento',
        count: 'Total de Cobranças',
        pending: 'Pendente',
        paid: 'Pago',
        view: 'Ver Faturas',
        add: 'Adicionar Fatura',
        delete: 'Cancelar Fatura',
        unlock: 'Desbloquear',
        trustUnlock: 'Desbloqueio de Confiança',
        lock: 'Suspender Acesso',
        alreadyTrust: 'Você já usou o desbloqueio de confiança.',
        confirmDelete: 'Deseja realmente cancelar esta fatura?',
        confirmDeleteSuccess: 'Fatura cancelada com sucesso!',
        confirmUnlock: 'Deseja realmente realizar o desbloqueio de confiança para o usuário %name%?',
        confirmUnlockSuccess: 'Usuário desbloqueado com sucesso.',
        confirmLock: 'Deseja realmente suspender a conta do usuário %name%?',
        confirmLockSuccess: 'Usuário suspenso com sucesso.',
        confirmCash: 'Deseja realmente marcar a fatura como recebida?',
        confirmCashSuccess: 'Operação realizada com sucesso.',
        receivedCash: 'Marcar como Recebido em Dinheiro',
        statuses: {
            TARKAN_GENERATED: "Fatura Manual por Sistema",
            PENDING: 'Aguardando Pagamento',
            RECEIVED_IN_CASH: 'Recebido em Dinheiro',
            RECEIVED: 'Recebido',
            CONFIRMED: 'Confirmado',
            OVERDUE: 'Vencido',
            REFUND_REQUESTED: 'Estorno Solicitado',
            REFUND_IN_PROGRESS: 'Estorno em Progresso',
            CHARGEBACK_REQUESTED: 'Chargeback Solicitado',
            CHARGEBACK_DISPUTE: 'Em Disputa',
            AWAITING_CHARGEBACK_REVERSAL: 'Disputa Vencida',
            DUNNING_REQUESTED: 'Em Processo de Negativação',
            DUNNING_RECEIVED: 'Recuperada',
            AWAITING_RISK_ANALYSIS: 'Aguardando Análise de Risco',
            CANCELED: 'Cancelado'
        },
        billedVsPaid: "Faturado vs Pago",
        outstandingBalance: "Saldo Pendente",
        nextInvoice: "Próxima Fatura",
        totalInvoices: "Total de Faturas",
        paymentDate: "Data de Pagamento",
        no: "Não",
        currency: "R$ {value}",
        link: "Pagar Agora",
        pendingCount: "Faturas Pendentes",
        pendingBalance: "Saldo Pendente",
        pay: "Pagar",
        generatePDF: "Gerar PDF",
        userInfo: "Informações do Cliente",
        contactInfo: "Informações de Contato",
        totalBilled: "Total Faturado",
        pendingAmount: "Valor Pendente",
        totalPaid: "Total Pago",
        balance: "Saldo",
        noInvoices: "Nenhuma fatura encontrada",
        financialSummary: "Resumo Financeiro",
        accountSummary: "Resumo da Conta",
        notAvailable: "Não disponível"
    },
    commands: {
        "command": "Comando",
        "search": "Pesquisar Comando",
        "title": "Título",
        "type": "Tipo",
        "new": "Novo Comando",
        "edit": "Editar Comando",
        "savedCommands": "Comandos Salvos",
        "confirmDelete": "Deseja realmente apagar este comando?",
        "changeNative": "Alterar a ação de comando nativo",
        "changeAction": "Ação nativa para mudar",
        "success": "Comando salvo com sucesso",
        "successUpdate": "Comando atualizado com sucesso",
        "error": "Erro ao salvar o comando",
        "errorUpdate": "Erro ao atualizar o comando",
        "deleted": "Comando excluído com sucesso"
    },
    attribute: {
        "marca": "Marca",
        "color": "Cor",
        "date": "Data",
        instalation: {
            "observation": "Observação da instalação",
            "data": "Data da instalação",
            "instalador": "Instalador",
            "email": "Email da instalação"
        },

        "broker": "Corretor",
        "motor": "Motor",
        "vin": "VIN",
        "chassis": "Chassi",
        "fuelTank": "Tanque de combustível",
        "observation": "Observação",


        "APN": "APN",
        "ValoMensual": "Valor mensal",
        "temp1": "Temperatura 1",
        "input": "Entrada",
        "output": "Saída",
        "mode": "Modo",
        "reason": "Razão",
        charge: "Carga",
        positionItems: 'Attributos Favoritos',
        zeroOdometer: 'Zero Odometro',
        qrDriverId: 'QR Motorista',
        facturacionActiva: 'Visível Faturas',
        title: 'Gerenciar Atributos',
        attribute: 'Atributo',
        "value": "Valor",
        "add": "Adicionar Atributo",
        attributes: 'Atributos',
        computedAttributes: 'Atributos Computados',
        result: 'Resultado',
        search: 'Pesquisar Atributo',
        id: 'ID',
        batteryLevel: 'Nível da Bateria',
        battery: 'Bateria',
        power: 'Tensão de Alimentação',
        rpm: 'RPM',
        alarms: 'Alarmes',
        totalDistance: 'Distância Total',
        deviceId: 'ID do Dispositivo',
        protocol: 'Protocolo',
        serverTime: 'Hora do Servidor',
        deviceTime: 'Hora do Dispositivo',
        rssi: 'Intensidade do Sinal',
        raw: 'Cru',
        adc1: 'Tensão da Bateria Veiculo',
        out: 'Saída',
        out1: 'Saída1',
        out2: 'Saída2',
        out3: 'Saída3',
        out4: 'Saída4',
        in: 'Entrada',
        in1: 'Entrada1',
        in2: 'Entrada2',
        in3: 'Entrada3',
        in4: 'Entrada4',
        fixTime: 'Hora do GPS',
        valid: 'Válido',
        spentFuel: 'Custo de Combustível',
        fuel: 'Combustível',
        outdated: 'Desatualizado',
        type: 'Tipo',
        versionFw: 'Versão FW',
        status: 'Status',
        index: 'Índice',
        priority: 'Prioridade',
        io200: 'io200',
        io1: 'io1',
        io2: 'io2',
        io68: 'io68',
        io69: 'io69',
        pdop: 'pdop',
        hdop: 'hdop',
        io24: 'io24',
        io9: 'io9',
        io6: 'io6',
        iccid: 'ICCID',
        operator: 'Operadora',
        driverUniqueId: 'ID do Motorista',
        ip: 'IP',
        latitude: 'Latitude',
        longitude: 'Longitude',
        details: 'Detalhe',
        position: 'Posição',
        time: 'Hora',
        dbgFlags: 'Bandeira',
        altitude: 'Altitude',
        address: 'Endereço',
        accuracy: 'Precisão',
        speed: 'Velocidade',
        odometer: 'Odômetro',
        hours: 'Horas',
        distance: 'Distância',
        course: 'Ângulo',
        motion: 'Movimento',
        alarm: 'Alarme',
        event: 'Evento',
        sat: 'Satélites',
        blocked: 'Bloqueado',
        ignition: 'Ignição',
        network: 'Conexão',
        speedLimit: 'Limite de Velocidade',
        lockOnExit: 'Bloqueio ao Sair da Cerca',
        lockOnEnter: 'Bloqueio ao entrar na Cerca',
        telegramChatId: 'ID do Telegram',
        currency: 'Moeda',
        fuelPrice: 'Preço do Combustível',
        litersx100km: 'Unidade de Combustível x 100 Km',
        fuelCost: 'Custo do Combustível',
        notificationTokens: 'Tokens de Notificação',
        temperature: 'Temperatura',
        temperature1: 'Temperatura 1',
        volumeUnit: 'Unidade de Volume',
        distanceUnit: 'Unidade de Distância',
        speedUnit: 'Unidade de Velocidade',
        placa: 'Placa',
        brocker: 'Brocker',
        telegramBot: 'Telegram Bot',
        timezone: 'Fuso Horario',
        google_api: 'Chave da API do Google'
    },
    editAttribute: {
        attributes_att: "Atributos",
        attributes_Info: "Informações",
        attributes_save: "Atributo computado cadastrado com sucesso.",
        attributes_vincu: "Vinculando atributo ao dispositivo",
        attributes_Add: "Cadastrando seu atributo...",
        attributes_Del: "Atributo computado deletado com sucesso.",
        attributes_Question1: "Deseja realmente excluir este atributo computado?",
        attributes_Question2: "Tem certeza?",
        attributes_Devices: "Dispositivos",
        attributes_resultado: "Resultado",
        attributes_Please_input: "Por favor, insira",
        attributes_não_Testar: "Não Testar",
        attributes_testar_Attribute: "Testar Atributo em",
        attributes_expresion: "Expressão",
        attributes_boolean: "Booleano",
        attributes_number: "Número",
        attributes_string: "String",
        attributes_typeOut: "Tipo de Saída",
        attributes_Attribute: "Atributos",
        attributes_description: "Descrição",
        attributes_command: "Comando",
        attributes_add_attribute: "Adicionar Atributo",
        attributes_Not: "Nenhum tipo disponível",
        telegramBot: 'Telegram Bot',
        timezone: 'Fuso Horario',
        attributes_grupo: "Grupo"
    },
    group: {
        add: "Cadastrar Grupo",
        "search": "Pesquisar Grupo",
        "new": "Criar Grupo",
        "title": "Gerenciar Grupos",
        "groups": "Grupos",
        "group": "Grupo",
        "edit": "Editar",
        "name": "Nome do Grupo",
        "father": "Pai do Grupo",
        "attributes": "Atributos"
    },
    server: {
        tarkan: 'Tarkan',
        restart: 'Reiniciar Servidor',
        lazyDeletion: 'Exclusão em Modo Seguro',
        enableQrDriverId: 'Habilitar Check-In por QrCode',
        enableAdvancedPerms: 'Sistema de Permissões Avançadas',
        language: 'Idioma',
        enableLockUnlock: 'Exibir botões de bloqueio e desbloqueio',
        deviceLimit: 'Limite de Dispositivos do Servidor',
        saving: 'Salvando suas alterações',
        saved: 'Operação realizada com sucesso.',
        server: 'Servidor',
        preferences: 'Preferências',
        permissions: 'Permissões',
        map: 'Mapa',
        latitude: 'Latitude',
        longitude: 'Longitude',
        zoom: 'Zoom',
        twelveHourFormat: 'Relógio de 12 Horas',
        registration: 'Registro',
        bingKey: 'Chave Bing',
        coordinateFormat: 'Formato de Coordenada',
        limitCommands: 'Limitar Comandos',
        deviceReadonly: 'Dispositivos Somente Leitura',
        google_key: 'API Google',
        readOnly: 'Somente Leitura',
        restartServer: 'Ao executar esta ação, o sistema pode demorar alguns minutos para voltar ao ar',
        restartServer1: 'Deseja realmente reiniciar o servidor?',
        grade: 'Graus',
        minute: 'Minutos',
        seconds: 'Segundos',
        showStops: 'Paradas de veículos',
        showEvents: 'Eventos de veículos',
        enableBilling: 'Financeiro',
        restartServerResult: 'Servidor reiniciando...'
    },
    driver: {
        generatePDF: 'Gerar PDF',
        driver: 'Motorista',
        drivers: 'Motoristas',
        name: 'Nome',
        saving: 'Salvando motorista',
        saved: 'Motorista salvo com sucesso!',
        saveError: 'Ocorreu um erro ao salvar o motorista!',
        uniqueId: 'Identificador Único',
        search: 'Pesquisar Motorista',
        selectError: 'Você precisa selecionar um motorista!',
        confirmDelete: 'Você está deletando o motorista "%name%", deseja realmente continuar?',
        deleteSuccess: 'Motorista deletado com sucesso.',
        add: 'Cadastrar Motorista',
        remove: 'Deletar Motorista',
        litersx100km: 'Combustível Consumido x 100 KM',
        fuelCost: "Custo de Combustível",
        edit: 'Editar Motorista',
        newDriver: 'Novo Motorista',
        editDriver: 'Editar Motorista',
        "cnhNumber": "Número da CNH",
        "cnhFiliation": "Filiação da CNH",
        "birthDate": "Data de Nascimento",
        "cpf": "CPF",
        "cnhCategory": "Categoria da CNH",
        "firstLicenseDate": "Data da Primeira Habilitação",
        "cnhValidity": "Validade da CNH",
        "cnhObservations": "Observações sobre a CNH",

        "qrCode": "QR Code do Motorista",
        "attributes": "Atributos",
        "devices": "Dispositivos",
        "info": "Informação",
        "success": "Sucesso",
        cnhState: "Estado da CNH",
        qrDriver: {
            enable: 'QRCode - Habilitar Acesso',
            username: 'Nome de Usuário',
            password: 'Senha',
            behaviorRules: 'Regras de Comportamento do Sistema',
            unlockDevice: 'Desbloquear Dispositivo',
            autoLogout: 'Auto Logout ao Desligar a Ignição',
            lockDevice: 'Bloquear o Dispositivo sem Motorista',
            lockDeviceTimeout: 'Realizar o Bloqueio Após',
            autoLogoutTimeout: 'Realizar o Logout Após',
            "disabled": "Desativado",
            "enabled": "Ativado",
            "no": "Não",
            "yes": "Sim",
            "minute": "minuto",
            "minutes": "minutos",


        },
    },

    "geofence": {
        "geofences": "Geocercas",
        switches: "Bloqueios ",
        "search": "Pesquisar Geocerca",
        "add": "Nova Geocerca",
        "name": "Título",
        "new": "Criar Geocerca",
        "edit": "Editar Geocerca",
        "attributes": "Atributos",
        "type": "Tipo de Geocerca",
        "line": "Linha",
        "circle": "Círculo",
        "polygon": "Polígono",
        "editArea": "Editar Geocerca",
        "totalArea": "Área Total",
        "lockOnEnter": "Bloquear ao Entrar",
        "speedLimit": "Bloquear ultrapassar o limite de velocidade",
        "lockOnExit": "Bloquear ao Sair",
        "deleteConfirm": "Deseja realmente apagar esta geocerca?",
        "errors": {
            "FILL_NAME": "O nome do campo é obrigatório."
        }
    },
    calendar: {
        title: 'Gerenciar Calendários',
        newCalendar: 'Novo Calendário',
        editCalendar: 'Editar Calendário',
        calendars: 'Calendários',
        calendar: 'Calendário',
        name: 'Título',
        data: 'Agendamentos',
        add: 'Adicionar Calendário',
        remove: 'Remover Calendário',
        edit: 'Editar Calendário',
        startDate: 'Data e Hora de Início',
        endDate: 'Data e Hora de Término',
        repeat: 'Repetir',
        frequency: 'Frequência',
        confirmAdd: 'Adicionar',
        expires: 'Termina em',
        occurrencies: 'Ocorrências',
        expiration: 'Data de Término',
        frequencyes: {
            daily: 'Todos os Dias',
            weekly: 'Toda Semana',
            monthly: 'Mensalmente',
            yearly: 'Anualmente',
        },
        "expirations": {
            "never": "Nunca",
            "count": "Depois de",
            "until": "Até"
        }
    },
    command: {
        savedCommands: 'Comandos Salvos',
        search: 'Pesquisar Comando'
    },
    maintenance: {
        search: 'BPesquisaruscar Manutenção',
        title: 'Gestão de Manutenções',
        newMaintenance: 'Adicionar Manutenção',
        editMaintenance: 'Editar Manutenção',
        maintence: 'Manutenção',
        maintenance: 'Manutenções',
        maintenances: 'Manutenções',
        name: 'Nome',
        type: 'Tipo',
        start: 'Início',
        cicle: 'Ciclo',
        add: 'Adicionar Manutenção',
        remove: 'Remover',
        edit: 'Editar',
        types: {
            totalDistance: 'Distância Total',
            hours: 'Horas'
        }
    },
    share: {
        add: 'Criar Compartilhamento',
        edit: 'Editar Compartilhamento',
        remove: 'Remover Compartilhamento'
    },

    linkObjects: {
        relatedTitle: "Objetos Relacionados",
        loading: "Carregando objetos relacionados...",
        cancel: "Cancelar",
        searchRelated: "Buscar objetos relacionados",
        name: "Nome",
        type: "Tipo",
        noRelated: "Nenhum objeto relacionado encontrado",
        noSearchResults: "Nenhum resultado encontrado para a busca",
        error: "Erro",
        errorLoading: "Erro ao carregar objetos relacionados",
        timeoutError: "A operação demorou muito tempo e foi cancelada",
        related: "Rel.",
        resultsOf: "resultados de",
        devices: "Dispositivos",
        commands: "Comandos",
        attributes: "Atributos",
        drivers: "Motoristas",
        users: "Usuários",
        calendars: "Calendários"
    },

    status: {
        online: "Online",
        offline: "Offline",
        idle: "Parado",
        blocked: "Bloqueado"
    },


};

export default messages;
